import { template as template_51780f760e77422caf0eb82ccbb2bd03 } from "@ember/template-compiler";
const FKLabel = template_51780f760e77422caf0eb82ccbb2bd03(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
