import { template as template_e0ac3b987cee416badc2e573d05b75ed } from "@ember/template-compiler";
const FKText = template_e0ac3b987cee416badc2e573d05b75ed(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
