import { template as template_a314690490c14675ab4fe5ac6b81dda0 } from "@ember/template-compiler";
const WelcomeHeader = template_a314690490c14675ab4fe5ac6b81dda0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
